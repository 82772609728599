<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/about">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.aboutCompany") }}</li>
      </ul>
    </div>
    <div class="main main_page bg-fff">
      <div class="about_one">
        <div class="one">
          <div class="title">
            <StaticImage url="about/about_01.png" />
            <span v-if="locale==='en'">About us</span>
            <span v-if="locale==='pb'">Sobre nós</span>
          </div>
          <div class="text"  v-if="locale==='en'">
            {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} is an important
            strategic partner of Amazon. Our company is full of "builders" who
            represent customers and innovate with different backgrounds, ideas
            and perspectives. Our views have become more diverse in many ways:
            including race, ethnicity, gender, age, physical and mental
            abilities, sexual orientation, religious beliefs, culture, language
            and educational background, as well as professional and life
            experience. We are committed to achieving diversity, equality and
            inclusiveness, and use our unique perspective to expand our
            influence and grow. Our mission is to be the most customer-centric
            company on the planet. <br /><br />
            <StaticImage url="about/about_02.jpg" />
            <br /><br />
            <h3><b>Diversity, equality and inclusion</b></h3>
            A diverse and inclusive team will have a positive impact on our
            products and services, helping us to better serve customers, sales
            partners, employees and communities with different backgrounds.
            <br /><br />
            <StaticImage url="about/about_03.jpg" />
            <br /><br />
            <h3><b>Our leadership guidelines</b></h3>
            Whether discussing new projects, identifying the best solutions for
            customers, or interviewing candidates, Amazon employees apply our
            leadership principles every day.
            <br />
            Amazon follows four principles: customer-centric rather than
            competitor-centric; passion for invention; commitment to operational
            excellence; and long-term thinking. Customer reviews, one-click
            shopping, personalized recommendations, Prime, Amazon Fulfillment,
            AWS, Kindle Direct Publishing, Kindle, Fire tablets, Fire TV, Amazon
            Echo and Alexa are some of the products and services created by
            Amazon.
          </div>

          <div class="text" v-if="locale==='pb'">
            {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} é um importante
            parceiro estratégico da Amazon. Nossa empresa está cheia de
            "construtores" que representam clientes e inovam com diferentes
            fundos, ideias e perspectivas. Nossas visões se tornaram mais
            diversas de muitas maneiras: incluindo raça, etnia, gênero, idade,
            habilidades físicas e mentais, orientação sexual, religiosas
            crenças, cultura, linguagem e fundo educacional, bem como
            profissional e vida. Estamos comprometidos a alcançar diversidade,
            igualdade e inclusividade, e usar nosso único perspectiva para
            expandir nossa influência e crescer. Nossa missão é ser a empresa
            mais centrada em clientes do planeta.

            <br /><br />

            <StaticImage url="about/about_02.jpg" />

            <br /><br />

            <h3><b>Diversidade, igualdade e inclusão</b></h3>

            A diverse and inclusive team will have a positive impact on our
            produtos e serviços, nos ajudando a servir melhor clientes, vendas
            parceiros, empregados e comunidades com contextos diferentes.

            <br /><br />

            <StaticImage url="about/about_03.jpg" />
            
            <br /><br />
            <h3><b>Nossas diretrizes de liderança</b></h3>
            Nossas diretrizes de liderança Se discutir novos projetos,
            identificar as melhores soluções para clientes, ou entrevistando
            candidatos, empregados da Amazônia aplicam nosso princípios de
            liderança todos os dias.

            <br />

            A Amazônia segue quatro princípios: cliente-centrado em vez de
            centro-competitor; paixão pela invenção; compromisso com a operação
            excelência; e pensamento a longo prazo. Revisões do cliente, um clic
            compras, recomendações personalizadas, Prime, Amazon Fulfillment,
            AWS, Kindle Direct Publishing, Kindle, Fire tablets, Fire TV, Amazon
            Echo e Alexa são alguns dos produtos e serviços criados por
            Amazônia.
          </div>
        </div>
        <!-- <div class="four">
          <div class="box">
            <div class="title"><span>Company qualification</span></div>
            <ul>
              <li>
                <p><StaticImage url="about/img2_01.png" /></p>
              </li>
            </ul>
            <ul>
              <li>
                <p><StaticImage url="about/img2_02.png" /></p>
              </li>
            </ul>
            <ul>
              <li>
                <p><StaticImage url="about/img2_03.png" /></p>
              </li>
            </ul>
            <ul>
              <li>
                <p><StaticImage url="about/img2_04.png" /></p>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import StaticImage from "@/components/StaticImage.vue";
import { useI18n } from "@/lang";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: { StaticImage },
  setup() {
    const { t,locale } = useI18n();
    return {
      t,
      locale,
      sysDataDictionaryWbObj,
    };
  },
};
</script>
<style>
/* Current page style */
</style>
